import "bootstrap-icons/font/bootstrap-icons.css";
import {Link} from 'react-router-dom';
import CartIcon from "../cart-icon/cart-icon";
import SearchComponent from "../search-component/search-component";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faVk} from "@fortawesome/free-brands-svg-icons";
import './header-logo.css';
import logo from './logo1.jpg';



const HeaderLogo =() => {
    return (
        <div className="wrapper-header">
            <div className="container wrapper-content">
                <div className="row ">
                    <div className=" col-12 d-none d-lg-block">
                        <div className="row">
                            <div className="col-4 d-flex align-items-center justify-content-start ">
                                <a href="https://vk.com/aleksseevaelena">
                                    <FontAwesomeIcon icon={faVk} style={{fontSize: '22px'}}/>
                                </a>
                                <a href="https://web.telegram.org/z/#-1324936203">
                                    <i style={{fontSize:"20px"}} className="bi bi-telegram ms-4"></i>
                                </a>

                            </div>
                            <div className="col-4 d-flex justify-content-center">
                            <Link to='/'>
                                <img src={logo} alt="logo" className="app-logo"/>
                            </Link>

                            </div>
                            <div className="col-4 d-flex align-items-center  ">
                                    <div className="col-10  ">
                                        <SearchComponent/>

                                    </div>
                                    <div className="col-2 d-flex justify-content-end  ">
                                        <CartIcon/>

                                    </div>
                            </div>


                        </div>

                    </div>

                </div>

            </div>
        </div>
    )
}

export default HeaderLogo;
