
const Contact =() =>{
    return(
        <div>
            <div className="undernav"></div>
            <div className='container wrapper-content'>
                <div className='wrapper-terms'>
                    <h5>
                    Контакты
                    </h5>
                    <div>
                        <p className="py-3">
                            НДП Подлубная Елена Фёдоровна <br/>
                            ИНН 744814182626 <br/>
                            г.Челябинск <br/>
                            ул. Чичерина, 5 <br/>
                            Whatsapp, Viber, Telegram: +7-963-468-6934 <br/>
                            Тел.: +7-963-468-6934
                        </p>
                        <h5>
                            Челябинск
                        </h5>
                        <br/>
                        <p>
                            <h6>Шоурум и студия LA Jewelry</h6>
                            ул. Омская, 95 <br/>
                            По предзаписи в мессенджерах <br/>
                            +7-963-468-6934 (телефон и whatsapp) <br/>
                            Оплата возможна наличными, переводом.
                        </p>
                        <p>
                            <h6>Салон красоты Biolage</h6>
                            Ул. Проспект Ленина, 25 <br/>
                            ПН-ВС с 9:00 до 20:00 <br/>
                            Оплата возможна наличными, картой.
                        </p>
                        <p>
                            <h6>Сеть магазинов ROS COLLECTION, ТРК ФОКУС</h6>
                            Молдавская, 16 &mdash; 2 и 3 этаж <br/>
                            Ул. Сони Кривой, 30 <br/>
                            Оплата возможна наличными, картой.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact;
